import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import MultiSelectUsers from '@/components/multiSelects/multi-select-users';
import MultiSelectFilials from '@/components/multiSelects/multi-select-filials';
import selectMeetingType from '@/components/inventory/select-meeting-type';
import SelectNotificationTypes from '@/components/inventory/select-notification-types';
import SelectDepartment from '@/components/inventory/select-department';

export default {
	components: {
		MultiSelectUsers,
		MultiSelectFilials,
		selectMeetingType,
		SelectNotificationTypes,
		SelectDepartment
	},
	data() {
		return {
			loadingData: false,
			updateFile: [],
			usersList: [],
			productList: [],
			selectedUsersList: [],
			minutes: [
				{
					value: 5,
					label: '5'
				}, 
				{
					value: 10,
					label: '10'
				},
				{
					value: 15,
					label: '15'
				},
				{
					value: 20,
					label: '20'
				},
				{
					value: 30,
					label: '30'
				},
				{
					value: 45,
					label: '45'
				},
			],
			selectedImage: {},
			dialogPictureVisible: false,
			fileAccepts: "image/png, image/jpeg, application/pdf, .doc,.docx,application/msword,.xls,.xlsx,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain,",
		};
	},
	computed: {
      ...mapGetters({
			rules: "workMeeting/rules",
			model: "workMeeting/model",
			columns: "workMeeting/columns",
			authUser: "auth/user",
			getUserSearch: "users/search",
			getInventoryUsers: "users/inventory",
      }),
  	},
	methods: {
		...mapActions({
			showInventoryUsers: "users/inventory",
			search: "users/search",
	   }),
		fileNameTruncate(file_name){
			return  _.truncate(file_name, {length: 40, omission: '...'})
	   },
		selectUsers(user) {
			let $el = event.target.closest(".users-dropdown-items");
			if (!this.selectedUsersList.some((person) => person.id === user.id)) {
				this.selectedUsersList.push(user);
				$el.classList.add("active");
			} else {
				this.selectedUsersList = this.selectedUsersList.filter(
				(item) => item.id !== user.id
				);
				$el.classList.remove("active");
			}
		},
		findResponsibleUsers() {
			const { usersDropdown } = this.$refs;
			let value = event.target.value;
			if (value) {
				this.searchUsers(value, this);
			}
			if (value) {
				usersDropdown.classList.add("active");
			} else {
				usersDropdown.classList.remove("active");
			}
		},
		setUsers() {
			this.form.participants = this.selectedUsersList.map(item => item.id);
			const { usersDropdown, responsibleUsersInput } = this.$refs;

			this.selectedUsersContainer = true;
			usersDropdown.classList.remove("active");
			responsibleUsersInput.value = "";
		},
		searchUsers: _.debounce((search, self) => {
			if (_.isFunction(self.search)) {
				self
				.search(search)
				.then((res) => {
					self.usersList = JSON.parse(JSON.stringify(self.getUserSearch.filter(item => item.id !== self.form.user_id)));
				})
				.catch((err) => {});
			}
		}, 1000),
		getSelectedUsers() {
			const { usersDropdown } = this.$refs;
			usersDropdown.classList.toggle("active");
			if (usersDropdown.classList.contains("active")) {
				this.showInventoryUsers().then(() => {
				this.usersList = JSON.parse(JSON.stringify(this.getInventoryUsers.filter(item => item.id !== (this.authUser ? this.authUser.id : null))));
				});
			}
		},
		removeFromSelectedUsers(user) {
			this.selectUsers(user);
			this.form.participants = this.selectedUsersList.map(item => item.id);
		},
		handleRemove(file, fileList){
			this.updateFile.forEach((element, index) => {
				if(element.name === file.name ){
				this.updateFile.splice(index, 1);
				}
			});
		},
		handleExceed(files, fileList) {
			this.$message.warning(this.$t('message.file_limit_warning', {number: this.file_limit}));
		},
		createImageList(file, fileList) {
			if (fileList.length > this.file_limit) {
				fileList.pop();
				return this.$message({
					type: "warning",
					message: this.$t('message.file_limit_warning', {number: this.file_limit}),
				});
			}
			else if (file.size > 5000000) {
				fileList.pop();
				return this.$message({
					type: "warning",
					message: this.$t('message.file_size_limit_warning', {number: 5}),
				});
			}
			else if(this.updateFile.some(item => item.name == file.name)) {
				this.updateFile.forEach((element, index) => {
					if(element.name === file.name && element.size === file.size){
						fileList.pop();
						return this.$message({
						type: "warning",
						message: this.$t('message.file_already_exists'),
						});
					}
				});
			}
			else{
				let new_file = file.raw;
				new_file.url = URL.createObjectURL(file.raw)
				this.updateFile.push(new_file);
			}
		},
		removeImage(index, fileList){
			if(fileList[index].name === this.form.cover_image){
				this.form.cover_image = '';
			}
			fileList.splice(index, 1);
		},
		showImage(file){
			if(file.name){
				if(['image/png', 'image/jpeg', 'jpeg', 'jpg', 'png'].includes(file.type || (/[^.]*$/.exec(file.name)[0])) || (file.raw && ['image/png', 'image/jpeg'].includes(file.raw.type))){
					this.selectedImage = file;
					this.dialogPictureVisible = true;
				}
				else{
					let link = document.createElement('a');
					link.href = file.url;
					link.download = file.name;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			}
		},
		afterLeave(){
			this.updateFile = [];
			this.usersList = [];
			this.productList = [];
			this.selectedUsersList = [];
			if (this.$refs['calendar']) this.$refs['calendar'].clearObject();
		}
	}
}